export default [
    {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/calendar/Calendar.vue"),
        meta: {
            action: "read",
            resource: "manage-schedule",
            
        },
    },
    {
        path: 'preview/:fileId',
        name: 'data-summary-preview',
        component: () => import('@/views/apps/gpt/PreviewPage.vue'),  // Adjust this path to your actual PreviewPage component
        props: true,
      },
      
    {
        path: "/GPT",
        name: "apps-gpt",
        component: () => import("@/views/apps/gpt/Gpt.vue"),
        meta: {
            public: true,
            //pageTitle: "AI Settlement Analysis",
            
        },
    },
    {
        path: "/gpt-archive",
        name: "apps-gpt-archive",
        component: () => import("@/views/apps/gpt/GptArchive.vue"),
        meta: {
            //pageTitle: "AI Settlement Analysis",
            
        },
    },

    // *===============================================---*
    // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
    // *===============================================---*
    // {
    //   path: '/apps/email',
    //   name: 'apps-email',
    //   component: () => import('@/views/apps/email/Email.vue'),
    //   meta: {
    //     contentRenderer: 'sidebar-left',
    //     contentClass: 'email-application',
    //   },
    // },
    // {
    //   path: '/apps/email/:folder',
    //   name: 'apps-email-folder',
    //   component: () => import('@/views/apps/email/Email.vue'),
    //   meta: {
    //     contentRenderer: 'sidebar-left',
    //     contentClass: 'email-application',
    //     navActiveLink: 'apps-email',
    //   },
    //   beforeEnter(to, _, next) {
    //     if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
    //     else next({ name: 'error-404' })
    //   },
    // },
    // {
    //   path: '/apps/email/label/:label',
    //   name: 'apps-email-label',
    //   component: () => import('@/views/apps/email/Email.vue'),
    //   meta: {
    //     contentRenderer: 'sidebar-left',
    //     contentClass: 'email-application',
    //     navActiveLink: 'apps-email',
    //   },
    //   beforeEnter(to, _, next) {
    //     if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
    //     else next({ name: 'error-404' })
    //   },
    // },

    // *===============================================---*
    // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/todo",
        name: "apps-todo",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
        },
    },
    {
        path: "/apps/todo/:filter",
        name: "apps-todo-filter",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
            navActiveLink: "apps-todo",
        },
        beforeEnter(to, _, next) {
            if (
                ["pending", "in progress", "completed", "cancelled"].includes(
                    to.params.filter
                )
            )
                next();
            else next({ name: "error-404" });
        },
    },
    {
        path: "/apps/todo/tag/:tag",
        name: "apps-todo-tag",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
            navActiveLink: "apps-todo",
        },
        // beforeEnter(to, _, next) {
        //     if (
        //         ["team", "low", "medium", "high", "update"].includes(
        //             to.params.tag
        //         )
        //     )
        //         next();
        //     else next({ name: "error-404" });
        // },
    },

    // *===============================================---*
    // *--------- CHAT  ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/chat",
        name: "apps-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "chat-application",
        },
    },

    // *===============================================---*
    // *--------- ECOMMERCE  ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/e-commerce/shop",
        name: "apps-e-commerce-shop",
        component: () =>
            import("@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue"),
        meta: {
            contentRenderer: "sidebar-left-detached",
            contentClass: "ecommerce-application",
            pageTitle: "Shop",
            breadcrumb: [
                {
                    text: "ECommerce",
                },
                {
                    text: "Shop",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/e-commerce/wishlist",
        name: "apps-e-commerce-wishlist",
        component: () =>
            import(
                "@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue"
            ),
        meta: {
            pageTitle: "Wishlist",
            contentClass: "ecommerce-application",
            breadcrumb: [
                {
                    text: "ECommerce",
                },
                {
                    text: "Wishlist",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/e-commerce/checkout",
        name: "apps-e-commerce-checkout",
        component: () =>
            import(
                "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
            ),
        meta: {
            pageTitle: "Checkout",
            contentClass: "ecommerce-application",
            breadcrumb: [
                {
                    text: "ECommerce",
                },
                {
                    text: "Checkout",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/e-commerce/:slug",
        name: "apps-e-commerce-product-details",
        component: () =>
            import(
                "@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue"
            ),
        meta: {
            pageTitle: "Product Details",
            contentClass: "ecommerce-application",
            breadcrumb: [
                {
                    text: "ECommerce",
                },
                {
                    text: "Shop",
                    active: true,
                },
                {
                    text: "Product Details",
                    active: true,
                },
            ],
        },
    },

    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/users/list",
        name: "apps-users-list",
        component: () => import("@/views/apps/user/users-list/UsersList.vue"),
        meta: {
            action: "read",
            resource: "manage-user",
            
        },
    },
    {
        path: "/apps/users/edit/:id",
        name: "apps-users-edit",
        component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
        meta: {
            action: "read",
            resource: "manage-user",
        }
    },

    // *===============================================---*
    // *--------- COMPANY ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/company/overview",
        name: "apps-company-overview",
        component: () => import("@/views/apps/company/overview"),
        meta: {
            pageTitle: "Company",
            resource: "manage-company",
            action: "read",
            breadcrumb: [
                {
                    text: "Company Overview",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/apps/company/connections",
        name: "apps-company-connections",
        component: () => import("@/views/apps/company/connections"),
        meta: {
            pageTitle: "Company",
            breadcrumb: [
                {
                    text: "Company Connections",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/company/domicile_data",
        name: "apps-company-domicile-data",
        component: () => import("@/views/apps/company/domicile_data"),
        meta: {
            pageTitle: "Company",
            breadcrumb: [
                {
                    text: "Company Domicile",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/apps/company/routes",
        name: "apps-company-routes",
        component: () => import("@/views/apps/company/routes"),
        meta: {
            pageTitle: "Company",
            breadcrumb: [
                {
                    text: "Company Routes",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/company/billing",
        name: "apps-company-billing",
        component: () => import("@/views/apps/company/billing"),
        meta: {
            pageTitle: "Company",
            breadcrumb: [
                {
                    text: "Company Billing",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/company/billing/choose",
        name: "apps-company-billing-choose",
        component: () => import("@/views/apps/company/billing/ChoosePlan.vue"),
        meta: {
            pageTitle: "Company",
            breadcrumb: [
                {
                    text: "Company Plan",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/company/domiciles",
        name: "apps-company-domiciles",
        component: () => import("@/views/apps/company/domiciles"),
        meta: {
            pageTitle: "Company",
            breadcrumb: [
                {
                    text: "Company Domiciles",
                    active: true,
                },
            ],
        },
    },


    // *===============================================---*
    // *--------- WEEKLY SCHEDULE ---- ---------------------------------------*
    // *===============================================---*
    // {
    //     path: "/apps/schedule/view",
    //     name: "apps-schedule-build",
    //     component: () => import("@/views/apps/schedule"),
    //     meta: {
    //         pageTitle: "Schedule",
    //         breadcrumb: [
    //             {
    //                 text: "View Schedule",
    //                 active: true,
    //             },
    //         ],
    //     },
    // },

    // *===============================================---*
    // *--------- PAYROLL ---- ---------------------------------------*
    // *===============================================---*
    // {
    //     path: "/apps/payroll/setting",
    //     name: "apps-payroll-setting",
    //     component: () => import("@/views/apps/payroll/setting"),
    //     meta: {
    //         pageTitle: "Payroll",
    //         breadcrumb: [
    //             {
    //                 text: "Payroll",
    //             },
    //             {
    //                 text: "Setting",
    //                 active: true,
    //             },
    //         ],
    //     },
    // },
    {
        path: "/apps/payroll/edit/:id",
        name: "apps-payroll-edit",
        component: () => import("@/views/apps/payroll/setting/Form.vue"),
        meta: {
            pageTitle: "Payroll",
            breadcrumb: [
                { text: "Payroll" },
                { text: "Driver Earning", active: true },
                { text: "Fixed Rate", active: true },
            ],
        },
    },
    {
        path: "/apps/payroll/earning",
        name: "apps-payroll-earning",
        component: () => import("@/views/apps/payroll/driver_earning"),
        meta: {
            pageTitle: "Payroll",
            breadcrumb: [
                {
                    text: "Driver Earning",
                    active: true
                },
            ],
        },
    },
    {
        path: "/apps/payroll/report/:id/:fromDate/:toDate/:paymentDate",
        name: "apps-payroll-report",
        component: () => import("@/views/apps/payroll/driver_earning/Form.vue"),
        meta: {
            pageTitle: "Payroll",
            breadcrumb: [
                { text: "Driver Earning" },
                { text: "View Report", active: true },
            ],
        },
    },
    {
        path: "/apps/payroll/company",
        name: "apps-payroll-company",
        component: () => import("@/views/apps/payroll/company_payroll"),
        meta: {
            pageTitle: "Payroll",
            breadcrumb: [
                { text: "Company Payroll",active: true },
            ],
        },
    },
    {
        path: "/apps/payroll/driver",
        name: "apps-payroll-driver",
        component: () =>
            import("@/views/apps/payroll/company_payroll/Form.vue"),
        meta: {
            pageTitle: "Payroll",
            breadcrumb: [
                { text: "Company Payroll" },
                { text: "Payroll for Driver", active: true },
            ],
        },
    },

    // *===============================================---*
    // *--------- FLEET ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/fleet/list",
        name: "apps-fleet-list",
        component: () => import("@/views/apps/fleet/tractor_list"),
        meta: {
            pageTitle: "Fleet",
            breadcrumb: [
                {
                    text: "Tractor List",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/fleet/maintenance",
        name: "apps-fleet-maintenance",
        component: () => import("@/views/apps/fleet/maintenance"),
        meta: {
            pageTitle: "Fleet",
            breadcrumb: [
                {
                    text: "Fleet Maintenance",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/fleet/map",
        name: "apps-fleet-map",
        component: () => import("@/views/apps/fleet/map"),
        meta: {
            pageTitle: "Fleet",
            breadcrumb: [
                {
                    text: "Fleet Map",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/apps/fleet/list/:id",
        name: "apps-fleet-documents",
        component: () => import("@/views/apps/fleet/tractor_list/documents_list"),
        meta: {
            pageTitle: "Fleet Documents List",
            breadcrumb: [
                {
                    text: "Tractor List",
                    to :'apps-fleet-list'
                },
                {
                    text: "Fleet Documents List",
                    active: true,
                },
            ],
        },
    },

    // *===============================================---*
    // *--------- SETTLEMENTS ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/settlements/uploading",
        name: "apps-settlement-uploading",
        component: () => import("@/views/apps/settlements/UploadingPage.vue"),
        meta: {
            pageTitle: "Settlements",
            breadcrumb: [
                {
                    text: "Upload",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/settlements",
        name: "apps-settlement-connection",
        component: () => import("@/views/apps/settlements/ConnectionPage.vue"),
        meta: {
            pageTitle: "Settlements",
            breadcrumb: [
                {
                    text: "Reports",
                    active: true,
                },
            ],
        },
    },

     // *===============================================---*
    // *--------- Reports ---- ---------------------------------------*
    // *===============================================---*

    {
        path: "/apps/reports/time_sheets",
        name: "apps-reports-timesheets",
        component: () => import("@/views/apps/reports/timesheets"),
        meta: {
            pageTitle: "Reports",
            breadcrumb: [
                {
                    text: "Time Sheet",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/reports/edit_time_sheets/:id/:driver_id",
        name: "apps-reports-edit-timesheets",
        component: () => import("@/views/apps/reports/timesheets/form.vue"),
        meta: {
            pageTitle: "Reports",
            breadcrumb: [
                {
                    text: "Time Sheets",
                    to: '/apps/reports/time_sheets',
                },
                {
                    text: "Edit Time Sheet",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/apps/reports/timeoff_requests/open",
        name: "apps-reports-timeoff-open-requests",
        component: () => import("@/views/apps/reports/time_off/open_requests"),
        meta: {
            pageTitle: "Open Requests",
            breadcrumb: [
                {
                    text: "Open Requests",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/apps/reports/timeoff_requests/completed",
        name: "apps-reports-timeoff-close-requests",
        component: () => import("@/views/apps/reports/time_off/close_requests"),
        meta: {
            pageTitle: "Completed Requests",
            breadcrumb: [
                {
                    text: "Completed Requests",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/apps/reports/timeoff_requests/edit/:id",
        name: "apps-reports-timeoff-open-requests-edit",
        component: () => import("@/views/apps/reports/time_off/adjust_request_form"),
        meta: {
            pageTitle: "Edit Requests",
            breadcrumb: [
                {
                    text: "Edit Requests",
                    active: true,
                },
            ],
        },
    },
    
    {
        path: "/apps/reports/mmr",
        name: "apps-reports-mmr",
        component: () => import("@/views/apps/reports/mmr"),
        meta: {
            pageTitle: "Reports",
            resource: 'manage-kpi',
            action: 'read',
            breadcrumb: [
                {
                    text: "MMR",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/reports/mileage",
        name: "apps-reports-mileage",
        component: () => import("@/views/apps/reports/mileage"),
        meta: {
            pageTitle: "Reports",
            resource: 'manage-kpi',
            action: 'read',
            breadcrumb: [
                {
                    text: "IVMR",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/apps/reports/ifta",
        name: "apps-reports-ifta",
        component: () => import("@/views/apps/reports/ifta"),
        meta: {
            pageTitle: "Reports",
            resource: 'manage-gf-statement',
            action: 'read',
            breadcrumb: [
                {
                    text: "IFTA",
                    active: true,
                },
            ],
        },
    },

    // *===============================================---*
    // *--------- DRIVERS ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/drivers",
        name: "apps-drivers",
        component: () => import("@/views/apps/drivers"),
        meta: {
            pageTitle: "Drivers",
            resource: 'manage-roaster',
            action: 'read',
            breadcrumb: [
                {
                    text: "Drivers List",
                    active: true,
                },
            ],
        },
    },

    // *===============================================---*
    // *--------- Utilities ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/utilities",
        name: "apps-utilities",
        component: () =>
            import("@/views/apps/utilities/Utilities.vue"),
        meta: {
            contentRenderer: "sidebar-left-detached",
            contentClass: "ecommerce-application",
            pageTitle: "Utilities",
            action: 'read',
            resource: 'manage-utilities',
            breadcrumb: [
                {
                    text: "Favorite Websites",
                    active:true
                },
            ],
        },
    },

    // *===============================================---*
    // *--------- FLEET ---- ---------------------------------------*
    // *===============================================---*
    {
        path: "/apps/fleet/tractorlist",
        name: "apps-fleet-list",
        component: () => import("@/views/apps/fleet/tractor_list"),
        meta: {
            pageTitle: "Fleet",
            resource: 'manage-fleet',
            action: 'read',
            breadcrumb: [
                {
                    text: "Tractor List",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/fleet/maintenance",
        name: "apps-fleet-maintenance",
        component: () => import("@/views/apps/fleet/maintenance"),
        meta: {
            pageTitle: "Fleet",
            resource: 'manage-fleet',
            action: 'read',
            breadcrumb: [
                {
                    text: "Maintenance",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/fleet/map",
        name: "apps-fleet-map",
        component: () => import("@/views/apps/fleet/map"),
        meta: {
            pageTitle: "Fleet",
            resource: 'manage-fleet',
            action: 'read',
            breadcrumb: [
                {
                    text: "Map",
                    active: true,
                },
            ],
        },
    },

    // *===============================================---*
    // *--------- KPIs ---- ---------------------------------------*
    // *===============================================---*
    
    {
        path: "/apps/kpi/daily",
        name: "apps-kpis-daily",
        component: () => import("@/views/apps/kpi/dailyreview/daily"),
        meta: {
            pageTitle: "KPIs",
            resource: 'manage-kpi',
            action: 'read',
            breadcrumb: [
                {
                    text: "Daily Review",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/kpi/weekly",
        name: "apps-kpis-weekly",
        component: () => import("@/views/apps/kpi/weeklyreview/weekly"),
        meta: {
            pageTitle: "KPIs",
            resource: 'manage-kpi',
            action: 'read',
            //redirectIfLoggedIn: false,
            breadcrumb: [
                {
                    text: "Weekly Review",
                    active: true,
                },
            ],
        },
    },
    // {
    //     path: "/apps/kpi/revenue",
    //     name: "kpis-revenue-week",
    //     component: () => import("@/views/apps/kpi/revenue/index.vue"),
    //     meta: {
    //         pageTitle: "KPIs",
    //         breadcrumb: [
    //             {
    //                 text: "Revenue Per Week",
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: "/apps/kpi/miles/total",
    //     name: "kpis-miles-total",
    //     component: () => import("@/views/apps/kpi/miles/Total.vue"),
    //     meta: {
    //         pageTitle: "KPIs",
    //         breadcrumb: [
    //             {
    //                 text: "Total Miles Per Week",
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: "/apps/kpi/miles/driver",
    //     name: "kpis-miles-driver",
    //     component: () => import("@/views/apps/kpi/miles/Driver.vue"),
    //     meta: {
    //         pageTitle: "KPIs",
    //         breadcrumb: [
    //             {
    //                 text: "Miles Per Week By Driver",
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: "/apps/kpi/miles/vehicle",
    //     name: "kpis-miles-vehicle",
    //     component: () => import("@/views/apps/kpi/miles/Vehicle.vue"),
    //     meta: {
    //         pageTitle: "KPIs",
    //         breadcrumb: [
    //             {
    //                 text: "Miles Per Week By Vehicle",
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: "/apps/kpi/mpg/vehicle",
    //     name: "kpis-mpg-week",
    //     component: () => import("@/views/apps/kpi/mpg/index.vue"),
    //     meta: {
    //         pageTitle: "KPIs",
    //         breadcrumb: [
    //             {
    //                 text: "MPG Per Week By Vehicle",
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: "/apps/kpi/fuelcost/total",
    //     name: "kpis-fuel-total",
    //     component: () => import("@/views/apps/kpi/fuelcost/Total.vue"),
    //     meta: {
    //         pageTitle: "KPIs",
    //         breadcrumb: [
    //             {
    //                 text: "Total Fuel Cost Per Week",
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: "/apps/kpi/fuelcost/vehicle",
    //     name: "kpis-fuel-vehicle",
    //     component: () => import("@/views/apps/kpi/fuelcost/Vehicle.vue"),
    //     meta: {
    //         pageTitle: "KPIs",
    //         breadcrumb: [
    //             {
    //                 text: "Fuel Cost Per Week By Vehicle",
    //             },
    //         ],
    //     },
    // },
];
