import axios from 'axios'
import { serverUri } from '@/config'
import { getUserData } from "@/auth/utils"
import {paginateArray, sortCompare} from "@/store/utils"

export default {
  namespaced: true,
  state: {
    userList: [],
    roles: [],
    
  },
  mutations: {
    ADD_USER (state, user) {
      state.userList.push(user);
    },
    SET_USERS (state, users){
      state.userList = users;
    },
    UPDATE_USER (state, updatedUser) {
      const index = state.userList.findIndex(u => u.id === updatedUser.id)
      if (index !== -1) {
        Vue.set(state.userList, index, updatedUser); // Vue.set ensures reactivity
      }
    },
    DELETE_USER (state, user) {
      const index = state.userList.indexOf(user)
      state.userList.splice(index, 1)
    },
    SET_ROLES(state, roleOptions) {
      state.roles = roleOptions;
    },
  },
  actions: {
    getRoles({commit}){

      const roleOptions = [];
      return new Promise((resolve, reject) => {
        axios.get(`${serverUri}/users/getrole`)
          .then(response => {
            response.data.map( item => {
              roleOptions.push({
                label: item.name,
                value: item.id,
              })
            });
            commit("SET_ROLES", roleOptions);
            resolve(roleOptions)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchUsers({commit, state}, queryParams = {}) {
      return new Promise((resolve, reject) => {
        const {
          q = '',
          perPage = 10,
          page = 1,
          sortBy = 'id',
          sortDesc = false,
          role = null,
          company = null,
          status = null,
        } = queryParams;
        
        const user = getUserData();
        axios.get(`${serverUri}/users/alluser/${user.company_id}`)
          .then(res => {
            const users = res.data.map(item => ({
              id: item.id,
              fullName: item.name,
              email: item.email,
              company_name: item.company_name,
              start_time: item.start_time,
              start_day: item.start_day,
              company_id: item.company_id,
              role: item.role_name,
              role_id: item.role,
              username: item.name,
              status: item.status,
              avatar: item.profile_photo_path,
            }));
            commit('SET_USERS', users);
    
            const queryLowered = q.toLowerCase();
            let array = user.role === 'Super Admin' ? state.userList : state.userList.filter(u => u.company_id === user.company_id);
    
            const filteredData = array.filter(u =>
              (u.username.toLowerCase().includes(queryLowered) || u.fullName.toLowerCase().includes(queryLowered)) &&
              (!role || u.role === role) &&
              (!company || u.company_name === company) &&
              (!status || u.status === status)
            );
    
            const sortedData = filteredData.sort(sortCompare(sortBy));
            if (sortDesc) sortedData.reverse();
    
            resolve({
              data: {
                users: paginateArray(sortedData, perPage, page),
                total: filteredData.length,
              }
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    
    fetchUser({state}, {id}) {
      
        const userId = id;
        
        const user = state.userList.find(u => u.id === Number(userId));
        
        
        return user || null;
    },
    async addUser({state, commit}, userData) {
        try{
          const user = userData;
            // Assign Status
            
          
            let newUser = {
                // id: Number(store.state.users.userList[store.state.users.userList.length - 1].id) + 1,
                fullName: user.fullName,
                username: user.fullName,
                email: user.email,
                company: user.company_id ? user.company_id : getUserData().company_id,
                role: user.role,
                //role_id: ,
                status: 'Active',
                //password: user.password
            }
            const res = await axios.post(`${serverUri}/users/saveuser`, newUser);
            commit('ADD_USER', res);
            return res;

        } catch (error) {
            console.error('Error adding user', error);
            throw error;
        }

        
    },
    
    updateUser({state,commit}, user) {
        
        return new Promise((resolve, reject) => {
          
          let newUser = {
            id: user.id,
            fullName: user.fullName,
            username: user.fullName,
            email: user.email,
            company: user.company_id,
            role: user.role_id,
            status: user.status,
            password: user.password,
            avatar: user.avatar,
          }
        
          axios.post(`${serverUri}/users/updateuser/${user.id}`, newUser).then(res => {
            
            commit( 'UPDATE_USER', res.data);
            resolve(res.data);
          })
          .catch(error => {
            console.error('Error updating user', error);
            reject(error);
          });
          
          state.userList.map(item => {
            if (item.id === user.id) {
              item.fullName = user.fullName;
              item.username = user.fullName;
              item.email = user.email;
              item.company = user.company;
              item.role = user.role;
              item.status = user.status;
              item.password = user.password;
              item.avatar = user.avatar;
            }
          });
        
        });
    },
    deleteUser({commit, state}, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${serverUri}/users/deleteuser/${id}`)
                .then((response) => { 
                  commit('DELETE_USER', id);
                  resolve(response);
                })
                .catch(error => reject(error));
            
        });
    },
    getuserpermission(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${serverUri}/users/getuserpermission/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        })
    
    },
  }
}
