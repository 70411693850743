export default [
  {
    path: '/dashboard',
    name: 'driver-dashboard',
    component: () => import('@/views/dashboard/driver/index.vue'),
    meta: {
      pageTitle: "Driver Dashboard",
      resource: 'manage-driver',
      action: 'read',
      redirectIfLoggedIn: false,
    },
    
  },
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/apps/kpis/weekly',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    resource: 'manage-kpi',
    action: 'read',
    redirectIfLoggedIn: false,
  },
]
