import axios from "@axios";
import { serverUri } from "@/config";

export default {
    namespaced: true,
    state: {
        connections: [],
    },
    getters: {},
    mutations: {
        addConnection(state, value) {
            state.connections = value;
        },
    },
    actions: {
        fetchConnections({ commit }, companyId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${serverUri}/api/company/getConnections/${companyId}`)
                    .then((response) => {
                        commit("addConnection", response.data);
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
};
