
import { serverUri } from "@/config";
export default {
  // Endpoints
  
  loginEndpoint: `${serverUri}/jwt/login`,
  registerEndpoint: `${serverUri}/jwt/register`,
  refreshEndpoint: `${serverUri}/jwt/refresh-token`,
  logoutEndpoint: `${serverUri}/jwt/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
