import { Ability, AbilityBuilder } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

const defineAbility = () => {
    const {can, cannot, build} = new AbilityBuilder(Ability);

// Add ability from localStorage if exists
    let storedAbility = JSON.parse(localStorage.getItem('userData')).ability;
    if (storedAbility) {
        try {
            storedAbility = JSON.parse(storedAbility);
            return new Ability(storedAbility);
        } catch (error) {
            console.warn('Error parsing stored ability. Using default.');
        }
    }

// Otherwise return initial ability
return initialAbility;


}

const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? (userData.ability ? userData.ability : null) : null

export default new Ability(existingAbility || initialAbility)
