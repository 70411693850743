import axios from '@axios'
import {getUserData} from '@/auth/utils'
import { serverUri } from '@/config'

function filterAndSortTasks(tasks, payload) {

  console.log("the payload for the sorting is ", payload);
  const { q , filter, tag, sortBy: sortByParam = 'latest' } = payload;
  
  let filteredTasks = tasks;
  console.log('filtering and sorting', filteredTasks);

  let sortDesc = true;
  const sortBy = (() => {
    if (sortByParam === 'title-asc') {
      sortDesc = false;
      return 'title';
    }
    if (sortByParam === 'title-desc') {
      sortDesc = true; // or you can set it to false for descending order
      return 'title';
    }
    if (sortByParam === 'due-date') {
      sortDesc = false;
      return 'dueDate';
    }
    return 'id';
  })();
  
  if (filter) {
    filteredTasks = filteredTasks.filter(task => task.status === filter);
  }
  if (tag) {
    filteredTasks = filteredTasks.filter(task => task.tags && task.tags.some(item => item.title === tag));
  }
  if (q) {
    const queryLowered = typeof q === 'string' ? q.toLowerCase() : '';
    console.log(queryLowered)
    filteredTasks = filteredTasks.filter(task => {
      const title = task.title || '';
      return typeof title === 'string' && title.toLowerCase().includes(queryLowered);
    });
  }

  const ids = [...new Set(filteredTasks.map(item => item.id))];
  const filteredData = ids.map(id => {
    const task = tasks.find(item => item.id === id);
    return task;
  })

  const sortTasks = () => (a, b) => {
    if (a[sortBy] < b[sortBy]) return sortDesc ? 1 : -1;
    if (a[sortBy] > b[sortBy]) return sortDesc ? -1 : 1;
    return 0;
  }

  const sortedData = filteredData.sort(sortTasks(sortBy));
  if (sortDesc) sortedData.reverse();


  return sortedData;
}



export default {
  namespaced: true,
  state: { taskList: [], workingTaskList:[], areTasksLoaded: false},
  getters: {
    filteredTasksByTag: (state) => (tag) => {
      return state.taskList.filter(task => 
        task.tags && JSON.parse(task.tags).some(e => e.title === tag)
      );
    },
    filteredTasksByFilter: (state) => (filter) => {
      return state.taskList.filter(task => task.status === filter);
    },
    sortedTasks: (state) => {
      return [...state.taskList].sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
    },
    // ... [other getters]
  },
  mutations: {
    SET_WORKING_TASK_LIST(state,workingTaskList) {
      state.workingTaskList = workingTaskList;
    },
    SET_TASK_LIST(state, taskList) {
      state.taskList = taskList;
    },
    ADD_TASK(state, task) {
      state.taskList.push(task);
    },
    UPDATE_TASK(state, updatedTask) {
      const index = state.taskList.findIndex((t) => t.id === updatedTask.id);
      if (index !== -1) {
        state.taskList.splice(index, 1, updatedTask);
      }
    },
    REMOVE_TASK(state, taskId) {
      state.taskList = state.taskList.filter((t) => t.id !== taskId);
    },
    SET_TASKS_LOADED(state, loaded){ 
      state.areTasksLoaded = loaded;
    },
    
  },
  
  actions: {

    fetchTasks(ctx, payload){
      return new Promise((resolve, reject) =>{
        if(!ctx.state.areTasksLoaded){
          let userData = getUserData();
          const url = `${serverUri}/todo/tasks/${userData ? userData.id : null}/${userData ? userData.company_id : null}`;
          axios.get(url).then(res => {
            const tasks = res.data.map(item => ({
              id: item.task_id,
              title: item.title,
              dueDate: item.due_date,
              interval: item.interval,
              description: item.description,
              recurring:{option: item.recurring},
              status: item.task_status,
              type: item.type,
              assignee: item.owner_id ? JSON.parse(item.owner_id) : [],
              tags: item.tags.length ? JSON.parse(item.tags) : [''],
              isPending: item.task_status === 'pending' ? true : false,
              isInProgress: item.task_status === 'in progress' ? true : false,
              isCompleted: item.task_status === 'completed' ? true : false,
              isCancelled: item.task_status === 'cancelled' ? true : false,
              
            }));
            ctx.commit('SET_TASK_LIST', tasks);
            ctx.commit('SET_TASKS_LOADED', true);

            resolve(filterAndSortTasks(tasks,payload));}).catch(error=> reject(error));
          }else{

            resolve(filterAndSortTasks(ctx.state.taskList,payload));
          }
        })
      },


    fetchTasksDeprecated(ctx, payload) {
      return new Promise((resolve, reject) => {

          // Extract parameters from payload
          const { q = '', filter, tag, sortByParam = 'latest' } = payload;

          // Construct the query parameters string
          const queryParams = new URLSearchParams();
          if (q) queryParams.append('q', q);
          if (filter) queryParams.append('filter', filter);
          if (tag) queryParams.append('tag', tag);
          if (sortByParam) queryParams.append('sortBy', sortByParam);

          // Construct the full URL with query parameters
          const url = `${serverUri}/todo/tasks/${userData ? userData.id : null}/${userData ? userData.company_id : null}?${queryParams.toString()}`;

          axios.get(url).then(res => {

              const tasks = res.data.map(item => ({
              
                id: item.task_id,
                title: item.title,
                dueDate: item.due_date,
                interval: item.interval,
                description: item.description,
                recurring: {option: item.recurring},
                status: item.task_status,
                type: item.type,
                assignee: item.owner_id ? JSON.parse(item.owner_id) : [],
                tags: item.tags.length ? JSON.parse(item.tags) : [''],
                isPending: item.task_status === 'pending' ? true : false,
                isInProgress: item.task_status === 'in progress' ? true : false,
                isCompleted: item.task_status === 'completed' ? true : false,
                isCancelled: item.task_status === 'cancelled' ? true : false,
              
              })); 
            ctx.commit('SET_TASK_LIST', tasks);
            ctx.commit('SET_TASKS_LOADED', true);
            
            resolve(tasks)
          }).catch((error) => { reject(error); });
          
                  
        })
        
  },
        
            
            
            /*let sortDesc = true;
            const sortBy = (() => {
              if (sortByParam === 'title-asc') {
                sortDesc = false;
                return 'title';
              }
              if (sortByParam === 'title-desc') return 'title';
              if (sortByParam === 'due-date') {
                sortDesc = false;
                return 'dueDate';
              }
              return 'id';
            })();

            const queryLowered = q.toLowerCase();
            const hasFilter = task => {
              if (filter ==='pending') return task.isPending && !task.isCancelled;
              if (filter ==='in-progress') return task.isInProgress && !task.isCancelled;
              if (filter ==='completed') return task.isCompleted && !task.isCancelled;
              if (filter ==='cancelled') return task.isCancelled;
              return !task.isCancelled;
            }

            const hasTags = task => {
              if (!tags.length) return true;
              return task.tags.some(tag => tags.includes(tag));
            }
            
            const filteredAllData = tasks.filter(task => { 
              return task.title.includes(queryLowered) && hasFilter(task) && hasTags(task);
            })

            const ids = [...new Set(filteredAllData.map(item => item.id))];
            const filteredData = ids.map(id => {
              const task = tasks.find(item => item.id === id);
              return task;
            })

            const sortTasks = () => (a, b) => {
              if (a[sortBy] < b[sortBy]) return sortDesc ? 1 : -1;
              if (a[sortBy] > b[sortBy]) return sortDesc ? -1 : 1;
              return 0;
            }

            const sortedData = filteredData.sort(sortTasks(sortBy));
            if (sortDesc) sortedData.reverse();
            */  
            
    
    fetchTags(ctx, payload){
      let taskTags, tagOptionsLeft, tagOptionsHandler,moveTasks = [];
      axios.
        get(`${serverUri}/todo/tag/get`)
          .then((res) => {
            taskTags = res.data.map((item) => {
                return {
                  ...item,
                }
            });
            tagOptionsLeft = res.data.map((item) => { 
                return {
                    color: item.color,
                    title: item.id,
                    label: item.title,
                    route: {
                      name: 'apps-todo-tag',
                        params: {
                          tag: item.title
                        }
                    }
                  }
              });
            tagOptionsHandler = res.data.map((item) => {
              return {
                  title: item.title,
                  label: item.title,
                }
              });
          });
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        console.log(taskData);
        taskData.user = getUserData().id;
        taskData.company = getUserData().company_id;
        axios.post(`${serverUri}/todo/add/${taskData.user}`, taskData)
          .then(response => {        
            ctx.commit('ADD_TASK', taskData); // Assume response.data is the new task
            resolve(response.data.all_tasks);
          })
          .catch(error => reject(error));
      });
    },

    updateTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        console.log("inside vuex store running action updateTask" , taskData.task) 
        //let taskId = taskData.id;
        axios.post(`${serverUri}/todo/update`, taskData.task)
          .then(response => {
            ctx.commit('UPDATE_TASK', taskData.task); // Assume response.data is the updated task
            resolve(response.data.all_tasks);
          })
          .catch(error => reject(error));
      });
    },

    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        let userId = getUserData().id;
        axios.delete(`${serverUri}/tasks/deleteTask/${id}/${userId}`)
          .then(response => {
            ctx.commit('REMOVE_TASK', id);
            resolve(response);
          })
          .catch(error => reject(error));
      });
    },

    submitCompanyDetails({ commit }, { phoneNumber, name, email, id, userId }) {
      return new Promise((resolve, reject) => {
        // Check if id is not null
        if (id !== null) {
          // Company has already been registered, return a message
          const existingCompanyMessage = 'Company has already been registered and is pending approval.';
          console.log(message);
          resolve({ message: existingCompanyMessage, success: false, company_id: id });
          return; // Exit the function
        }
    
        // If id is null, proceed with axios POST request
        axios
          .post(`${serverUri}/api/company/register`, { phoneNumber, name, email, userId })
          .then(response => {

            const userDataString = localStorage.getItem('userData');
    
            if (userDataString) {
              const userData = JSON.parse(userDataString);
              userData.company_id = response.data.company_id;
              const updatedUserDataString = JSON.stringify(userData);
              localStorage.setItem('userData', updatedUserDataString);
            }
            resolve({ message: 'Submission successful', ...response.data });
            console.log('Submission successful', response.data);

          })
          .catch(error => {
            // Handle errors
            console.error('Submission failed', error);
            reject({ message: 'Submission failed. Company not registered.', error });

          });
      });
    }
    
    
  },
}
