import axios from 'axios'
import { serverUri } from '@/config'

export default {
  namespaced: true,
  state: {
    authUserList: [],
    user: null,
    token: null,
    permissions: [],
  },
  mutations: {
    addAuth (state, payload) {
        state.authUserList = payload;
    },
    SET_USER (state, user) {
        state.user = user;
    },
    SET_TOKEN(state, token){
      state.token = token;
    },
    LOGOUT(state){
      state.user = null;
    },
    SET_PERMISSIONS(state, permissions){
      state.permissions = permissions;
    },
    
  },

  actions: {
    addAuthAction ({commit}, payload) {
        commit('addAuth', payload)
    },
    authLogin({commit}, credentials){
      commit('AUTH_LOGIN',credentials)

    },
    async login({commit,dispatch}, credentials) {

      const {email, password} = credentials;      
      try{
        
        const response = await axios.post(`${serverUri}/login`, {
            email: email,
            password: password,
        });
        console.log("response from the login is ", response);
        if (!response.data.status || response.data.status == 0) {
          console.log("status invalid");
          throw new Error(response.data.msg || 'Login failed');
        }
        
        await dispatch('setPermissions', response.data)
        
        commit('SET_USER', response.data);
        
        //commit('SET_TOKEN', response.data.token);

          }catch(error){
            console.error('Login error', error.response.data.msg);
            throw error;
          };         
    },
    async setPermissions({commit}, userData){
      
      try {
        
        const userId = userData.id; // Get the ID of the specific user you want to retrieve

        const userPermissionsRes = await axios.get(`${serverUri}/users/getuserpermission/${userId}`);

        console.log(userPermissionsRes);
        
        let userPermissions = [];
    
        if (userPermissionsRes.data.length) {
          userPermissionsRes.data.forEach(permission => {
            userPermissions.push({ action: 'read', subject: permission.action });
          });
        }
       
        if (userPermissions.length === 0) {
          // Add default permissions if the user has none
          userPermissions.push({ action: 'read', subject: 'manage-task' });
          userPermissions.push({ action: 'read', subject: 'manage-gf-statement' })
          userPermissions.push({ action: 'read', subject: 'manage-company' });
          // userPermissions.push({ action: 'read', subject: 'manage-utilities' }, { action: 'read', subject: 'manage-dashboard' });
        }
        commit('SET_PERMISSIONS', userPermissions);
    
        // Add the "ability" key with the userPermissions value
        userData.ability = userPermissions;
        
        localStorage.setItem("userData", JSON.stringify(userData));
    
    }catch(error){throw error;}
  },
    authLogout({commit}){},
  },
  
  getters:{
    isAuthenticated(state){
      return !!state.token;
    },
  }
}
