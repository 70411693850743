
export default({
  state: {
    namespaced: true,
    tasks: [{description: 'hello', category: 'world'}]
  },
  mutations: {
    ADD_TASK(state, task) {
      state.tasks.push(task)
    },
    DELETE_TASK(state, taskIndex) {
      state.tasks.splice(taskIndex, 1)
    },
    EDIT_TASK(state, { taskIndex, description }) {
      state.tasks, taskIndex, { ...state.tasks[taskIndex], description }
    },
    CATEGORIZE_TASK(state, { taskIndex, category }) {
      state.tasks, taskIndex, { ...state.tasks[taskIndex], category }
    }
  },
  actions: {
    addTask({ commit }, task) {
      commit('ADD_TASK', task)
    },
    deleteTask({ commit }, taskIndex) {
      commit('DELETE_TASK', taskIndex)
    },
    editTask({ commit }, payload) {
      commit('EDIT_TASK', payload)
    },
    categorizeTask({ commit }, payload) {
      commit('CATEGORIZE_TASK', payload)
    }
  },
  getters: {
    tasksByCategory: (state) => (category) => {
      return state.tasks.filter(task => task.category === category)
    }
  }
})
