import axios from 'axios'
import { serverUri } from '@/config'

export default {
  namespaced: true,
  state: {
    avatar: '',
    driversList: [],
    driversEdit: {
      driver_id: '',
      driver_name: '',
      email: '',
      phone: '',
      license: '',
      address: '',
      price_per_mile: '',
      work_status: { title: 'Working now', value: 1 },
      photo: '',
      domicile: '',
      ssn: '',
      uniforms: {
        shirt: '',
        pants: '',
        jacket: ''
      },
      hire_date: '',
      termination_date: '',
      notes: ''
    },
  },
  mutations: {
    addDriver (state, payload) {
        state.driversList = payload;
    },
    addAvatar (state, payload) {
        state.avatar = payload;
    },
    editDriver (state, payload) {
        state.driversEdit = payload;

        if (payload.work_status === 1) {
            state.driversEdit.work_status = { title: 'Working now', value: 1 }
        }
        else if (payload.work_status === 0) {
          state.driversEdit.work_status = { title: 'No longer working', value: 0 }
        }
    },
    removeDriver (state, payload) {
        const list = state.driversList;
        state.driversList.splice(0, state.driversList.length);
    }
  },
  actions: {
    addDriverAction ({commit}, payload) {
        commit('addDriver', payload)
    },
    addAvatarAction ({commit}, payload) {
        commit('addAvatar', payload)
    },
    editDriverAction ({commit}, payload) {
        commit('editDriver', payload)
    },
    // updateDriverAction ({commit}, payload) {
    //     commit('updateDriver', payload)
    // },
    removeDriverAction ({commit}, payload) {
        commit('removeDriver', payload)
    }
  },
}
