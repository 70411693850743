export default [
    {
      path: '/time-clock',
      name: 'time-clock',
      component: () => import('@/views/dashboard/driver/time_clock.vue'),
      meta: {
        pageTitle: 'Time Clock',
        breadcrumb: [
          {
            text: 'Time Clock',
          },
        ],
      },
      
      
    },
    {
      path: '/clock-history',
      name: 'clock-history',
      component: () => import('@/views/dashboard/driver/clock_history/clock_history.vue'),
      meta: {
        pageTitle: 'Clock History',
        breadcrumb: [
          {
            text: 'Clock History',
          },
        ],
      },
      
    },
    {
      path: '/clock-history-edit/:id',
      name: 'clock-history-edit',
      component: () => import('@/views/dashboard/driver/clock_history/clockEdit.vue'),
      meta: {
        pageTitle: 'Clock History Edit',
        breadcrumb: [
          {
            text: 'Clock History Edit',
          },
         
        ],
      },
     
    },
    {
      path: '/request-time-off',
      name: 'request-time-off',
      component: () => import('@/views/dashboard/driver/request_time_off/form.vue'),
      meta: {
        pageTitle: 'Request Time Off',
        breadcrumb: [
          {
            text: 'Request Time Off',
          },
        ],
      },
     
      
    },
    {
      path: '/earnings',
      name: 'earnings',
      component: () => import('@/views/dashboard/driver/earnings/index.vue'),
      meta: {
        pageTitle: 'Earnings',
        // breadcrumb: [
        //   {
        //     text: 'Earnings',
        //   },
        // ],
      },
      
    },

    {
      path: '/vehicle_documents',
      name: 'vehicle_documents',
      component: () => import('@/views/dashboard/driver/vehicle/vehicle_documents.vue'),
      meta: {
        pageTitle: 'Vehicle Documents',
        breadcrumb: [
          {
            text: 'Vehicle Documents',
          },
        ],
      },
      
    },
    {
      path: '/vehicle_documents_details',
      name: 'vehicle_documents_details',
      component: () => import('@/views/dashboard/driver/vehicle/vehicle_documents_details.vue'),
      meta: {
        pageTitle: 'Vehicle Documents Details',
        breadcrumb: [
          {
            text: 'Vehicle Documents Details',
          },
        ],
      },
     
    },
    {
      path: '/vehicle_defects_category',
      name: 'apps-vehicle-defects-category',
      component: () => import('@/views/apps/inspection/vehicle_defects_category/index.vue'),
      meta: {
        pageTitle: 'Vehicle Defects Category',
        breadcrumb: [
          {
            text: 'Vehicle Defects Category',
          },
        ],
      },
     
    },

    {
      path: '/vehicle_defects',
      name: 'apps-vehicle-defects',
      component: () => import('@/views/apps/inspection/vehicle_defects/index.vue'),
      meta: {
        pageTitle: 'Fleet Inspection Form',
        breadcrumb: [
          {
            text: 'Fleet Inspection Form',
            active:true
          },
        ],
      },
      
    },
    {
      path: '/vehicle/inspection',
      name: 'Completed Inspections',
      component: () => import('@/views/dashboard/driver/inspection/index.vue'),
      meta: {
        pageTitle: 'Vehicle Inspection',
        breadcrumb: [
          {
            text: 'Vehicle Inspection',
          },
        ],
      },
      
    },
    {
      path: '/vehicles/inspection_data',
      name: 'apps-vehicle-inspections',
      component: () => import('@/views/apps/inspection/index.vue'),
      meta: {
        pageTitle: 'Completed Inspections',
        breadcrumb: [
          {
            text: 'Completed Inspections',
            active: true
          },
        ],
      },
     
    },
    {
      path: '/vehicles/inspection_pdf/:id',
      name: 'apps-vehicle-inspections-pdf',
      component: () => import('@/views/apps/inspection/pdf.vue'),
      meta: {
        pageTitle: 'Completed Inspections',
        breadcrumb: [
          {
            text: 'Completed Inspections',
          },
          {
            text: 'View Details',
            active: true
          }
        ],
      },
     
    },
  ]
  