import useJwt from '@/auth/jwt/useJwt'
import axios from 'axios'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') //&& localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}
export function getRefreshTokenFromCookie() {

  const name = 'refreshToken='; // Name of the refresh token cookie
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null; // Return null if refresh token cookie is not found
}
export const saveAccessToken =(accessToken) => { 
  const userData = getUserData();

  // Modify the accessToken property of the userData object
  userData.accessToken = accessToken;

  // Convert the modified object back to a JSON string
  const updatedUserDataString = JSON.stringify(userData);

  // Update the userData object in localStorage with the modified JSON string
  localStorage.setItem('userData', updatedUserDataString);

  }
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  console.log("getting home route for logged in user  ", userRole)
  if (userRole === 'Super Admin' || userRole === 'Admin') return 'apps/kpi/weekly';
  if (userRole === 'User' ) return '/apps/todo/pending';
  if (userRole === 'Driver') return '/dashboard';
  if (userRole === 'Guest') return '/apps/todo/pending';
  // if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}


