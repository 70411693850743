import Vue from "vue";
import Vuex from "vuex";

// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import connectionStoreModule from "@/views/apps/company/connections/connectionStoreModule";
import utilitiesStoreModule from "@/views/apps/utilities/utilitiesStoreModule";
import todoStoreModule from "@/views/apps/todo/todoStoreModule"
import app from "./app";
import auth from "./auth";
import tasks from "./tasks";
import users from "./users";
import drivers from "./drivers";
import payroll from "./payroll";
import schedule from "./schedule";
import settlement from "./settlement";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        auth,
        tasks,
        users,
        schedule,
        driversStore: drivers,
        payrollStore: payroll,
        settlementStore: settlement,
        "app-utilities": utilitiesStoreModule,
        "app-ecommerce": ecommerceStoreModule,
        "app-todo": todoStoreModule,
        appConnection: connectionStoreModule,
    },
    strict: process.env.DEV,
});
